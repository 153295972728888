import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, XYZ, TileWMS, TileArcGISRest, WMTS, StadiaMaps, Vector as VectorSource, TileJSON } from 'ol/source';
import { Circle, Fill, Stroke, Style, Icon } from 'ol/style';
import proj4 from 'proj4';
import Projection from 'ol/proj/projection';
import { register } from 'ol/proj/proj4';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { getTopLeft } from 'ol/extent';
import { EsriJSON, GeoJSON, KML } from 'ol/format';
import { createXYZ } from 'ol/tilegrid';
import { tile as tileStrategy, bbox as bboxStrategy } from 'ol/loadingstrategy';


export function xyzLayer(title, url, params = {}, sourceParams = {}) {
  return new TileLayer({
    title: title,
    source: new XYZ({
      url: url,
      ...sourceParams
    }),
    visible: false,
    ...params
  });
}

export function osmLayer(title, url, params = {}, sourceParams = {}) {
  return new TileLayer({
    title: title,
    source: new OSM({
      url: url,
      ...sourceParams
    }),
    visible: false,
    ...params
  });
}

export function stadiaMapsLayer(title, layer, params = {}, sourceParams = {}) {
  return new TileLayer({
    title: title,
    source: new StadiaMaps({
      layer: layer,
      ...sourceParams
    }),
    visible: false,
    type: 'base',
    ...params
  })
}

export function hereLayer(title, scheme, base, params = {}, sourceParams = {}) {
  const url = 'https://{1-4}.' + base + '.maps.ls.hereapi.com' +
        '/maptile/2.1/maptile/newest/' + scheme + '/{z}/{x}/{y}/256/png' +
        '?app_id=1xsTQWoOG8xHNT09QZui&apiKey=ReA8a3lE9mosVaygKWx7b-RoPMCcg2aCxTYMLrdaaL0';
  return xyzLayer(title, url, params, sourceParams);
}

export function tomtomLayer(title, layer, params = {}, sourceParams = {}) {
  return osmLayer(title, 'https://{a-d}.api.tomtom.com/map/1/tile/' + layer + '/{z}/{x}/{y}.png?key=frW7D61EAAvXiSB52y28paSscHY1mymz', params, sourceParams);
}

export function mapboxLayer(title, style, params = {}, sourceParams = {}) {
  const url = 'https://api.mapbox.com/styles/v1/mapbox/' + style + '/tiles/512/{z}/{x}/{y}@2x'+
      '?access_token=pk.eyJ1IjoiZWRld2FhbDk3IiwiYSI6ImNrYWlkNnI3eDBvenkyc295aGVoOHQycjQifQ.ax4GeXWpjTldWb5pK4ellw'
  sourceParams = {
    attributions: '© Mapbox',
    ...sourceParams
  }
  return osmLayer(title, url, params, sourceParams);
}

export function cartoLayer(title, layer, params = {}, sourceParams = {}) {
  return osmLayer(title, 'https://{a-z}.basemaps.cartocdn.com/'+ layer +'/{z}/{x}/{y}.png', params, sourceParams);
}

export function cartoFastlyLayer(title, layer, params = {}, sourceParams = {}) {
  return osmLayer(title, 'https://cartocdn_{a-d}.global.ssl.fastly.net/'+ layer +'/{z}/{x}/{y}.png', params, sourceParams);
}

export function locationIqLayer(title, style, params = {}, sourceParams = {}) {
  const url = 'https://tiles.locationiq.com/v3/' + style + '/r/{z}/{x}/{y}.png?key=pk.5eb6a7c7b335681465faf171a60b7b29';
  sourceParams = {
    attributions: '<a href="https://locationiq.com/?ref=maps" target="_blank">© LocationIQ</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
    ...sourceParams
  }
  return xyzLayer(title, url, params, sourceParams);
}

export function mapyLayer(title, layer, params = {}, sourceParams = {}) {
  const url = 'https://api.mapy.cz/v1/maptiles/' + layer + '/tiles.json?lang=nl&apikey=_36hnurVFRlZXl0evL8HjBF_PeSv6y-WDoa0CznIZnc'
  return new TileLayer({
    title: title,
    source: new TileJSON({
      url: url,
      attributions: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
      ...sourceParams
    }),
    visible: false,
    ...params
  });
}

export function jawgLayer(title, layer = '', params = {}, sourceParams = {}) {
  var url = '';
  if (layer.length === 0) {
    var url = 'https://tile.jawg.io/{z}/{x}/{y}.png?api-key=ZR4LYGC8qXJuh254i19SF1VaSx5CiSoauHTMJZJqVfKHaPTr3DZmr4bP3IDELehW';
  } else {
    var url = 'https://tile.jawg.io/' + layer +'/{z}/{x}/{y}.png?api-key=ZR4LYGC8qXJuh254i19SF1VaSx5CiSoauHTMJZJqVfKHaPTr3DZmr4bP3IDELehW';
  }
  return xyzLayer(title, url, params, {
    attributions: 'Tiles courtesy of <a href="https://www.jawg.io/en/" target="_blank">jawgmaps</a>',
    ...sourceParams
  })
}

export function esriLayer(title, layer, params = {}, sourceParams = {}) {
  const url = 'https://server.arcgisonline.com/ArcGIS/rest/services/' + layer + '/MapServer/tile/{z}/{y}/{x}'
  sourceParams = {
    attributions: 'Powered by Esri',
    ...sourceParams
  }
  return xyzLayer(title, url, params, sourceParams)
}

export function googleLayer(title, layer, params = {}, sourceParams = {}) {
  return xyzLayer(title, 'https://mt0.google.com/vt/lyrs='+ layer +'&hl=en&x={x}&y={y}&z={z}', params, sourceParams)
}

export function thunderforestLayer(title, layer, params = {}, sourceParams = {}) {
  return osmLayer(title, 'https://{a-c}.tile.thunderforest.com/'+ layer +'/{z}/{x}/{y}.png?apikey=647abfae4a744c5cba2b9aeb28de8bdc', params, sourceParams)
}

export function tileWmsLayer(title, url, layers = '', params = {}, sourceParams = {}) {
  if(!sourceParams.hasOwnProperty('params')) {
    sourceParams.params = {}
  }
  sourceParams['params']['LAYERS'] = layers;
  return new TileLayer({
    title: title,
    source: new TileWMS({
      url: url,
      ...sourceParams,
    }),
    visible: false,
    ...params
  });
}

export function tileArcGisRestLayer(title, url, layers = '', params = {}, sourceParams = {}) {
  if(!sourceParams.hasOwnProperty('params')) {
    sourceParams.params = {}
  }
  sourceParams['params']['LAYERS'] = layers;
  return new TileLayer({
    title: title,
    source: new TileArcGISRest({
      url: url,
      ...sourceParams,
    }),
    visible: false,
    ...params
  });
}


// THINGS FOR PROJECTION EPSG:28992 --> Dutch Projection, centered in Amersfoort
proj4.defs("EPSG:28992","+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs");
register(proj4);

var proj28992Extent = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999]
var proj28992 = new Projection({
  code:"EPSG:28992",
  extent:proj28992Extent
})
var epsg28992Resolutions = [
  3440.640,
  1720.320,
  860.160,
  430.080,
  215.040,
  107.520,
  53.760,
  26.880,
  13.440,
  6.720,
  3.360,
  1.680,
  0.840,
  0.420,
  0.210,
  0.105
]
var epsg28992MatrixIds = new Array(15);
for (var z = 0; z < 15; ++z) {
  epsg28992MatrixIds[z] = "EPSG:28992:" + z;
}

export function epsg28992WmtsLayer(title, layer, url, params = {}, sourceParams = {}) {
  return new TileLayer({
    title: title,
    source: new WMTS({
      url: url,
      layer: layer,
      matrixSet: "EPSG:28992",
      format: "image/png",
      projection: proj28992,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(proj28992Extent),
        resolutions: epsg28992Resolutions,
        matrixIds: epsg28992MatrixIds
      }),
      style: "default",
      wrapX: false,
      ...sourceParams,
    }),
    type: 'base',
    visible: false,
    ...params,
  });
}

/*
 ███████████                     █████                                  █████                                                      
░░███░░░░░░█                    ░░███                                  ░░███                                                       
 ░███   █ ░   ██████   ██████   ███████   █████ ████ ████████   ██████  ░███         ██████   █████ ████  ██████  ████████   █████ 
 ░███████    ███░░███ ░░░░░███ ░░░███░   ░░███ ░███ ░░███░░███ ███░░███ ░███        ░░░░░███ ░░███ ░███  ███░░███░░███░░███ ███░░  
 ░███░░░█   ░███████   ███████   ░███     ░███ ░███  ░███ ░░░ ░███████  ░███         ███████  ░███ ░███ ░███████  ░███ ░░░ ░░█████ 
 ░███  ░    ░███░░░   ███░░███   ░███ ███ ░███ ░███  ░███     ░███░░░   ░███      █ ███░░███  ░███ ░███ ░███░░░   ░███      ░░░░███
 █████      ░░██████ ░░████████  ░░█████  ░░████████ █████    ░░██████  ███████████░░████████ ░░███████ ░░██████  █████     ██████ 
░░░░░        ░░░░░░   ░░░░░░░░    ░░░░░    ░░░░░░░░ ░░░░░      ░░░░░░  ░░░░░░░░░░░  ░░░░░░░░   ░░░░░███  ░░░░░░  ░░░░░     ░░░░░░  
                                                                                               ███ ░███                            
                                                                                              ░░██████                             
                                                                                               ░░░░░░                              
*/

export function esriFeatureLayer(title, serviceUrl, layer, params = {}, sourceParams = {}) {
  var esrijsonFormat = new EsriJSON();

  var vectorSource = new VectorSource({
    loader: function (extent, resolution, projection) {
      var url =
        serviceUrl +
        layer +
        '/query/?f=json&' +
        'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
        encodeURIComponent(
          '{"xmin":' +
            extent[0] +
            ',"ymin":' +
            extent[1] +
            ',"xmax":' +
            extent[2] +
            ',"ymax":' +
            extent[3] +
            ',"spatialReference":{"wkid":102100}}'
        ) +
        '&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*' +
        '&outSR=102100';
      $.ajax({
        url: url,
        dataType: 'jsonp',
        success: function (response) {
          if (response.error) {
            console.log(
              response.error.message + '\n' + response.error.details.join('\n')
            );
          } else {
            // dataProjection will be read from document
            var features = esrijsonFormat.readFeatures(response, {
              featureProjection: projection,
            });
            if (features.length > 0) {
              vectorSource.addFeatures(features);
            }
          }
        },
      });
    },
    strategy: tileStrategy(
      createXYZ({
        tileSize: 512,
      })
    ),
    ...sourceParams,
  });

  return new VectorLayer({
    title: title,
    source: vectorSource,
    // style: function (feature) {
    //   var classify = feature.get('GEBIEDNA_4');
    //   return styleCache[classify];
    // },
    style: new Style({
      fill: new Fill({
        color: 'rgba(0, 0, 0, 255)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 255)',
        width: 0.4,
      }),
      image: new Circle({
        fill: new Fill({
          color: 'rgba(0, 0, 0, 255)',
        }),
        radius: 7,
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 255)',
          width: 1,
        }),
      })
    }),
    visible: false,
    ...params,
  });
}

export function geoJSONLayer(title, url, params = {}, sourceParams = {}) {
  return new VectorLayer({
    title: title,
    source: new VectorSource({
      url: url,
      format: new GeoJSON(),
      ...sourceParams,
    }),
    visible: false,
    ...params,
  });
}

export function wfsLayer(title, url, layer, params = {}, sourceParams = {}) {
  return new VectorLayer({
    title: title,
    source: new VectorSource({
      format: new GeoJSON(),
      url: function(extent) {
        return(
          url +
          'version=1.1.0&request=GetFeature&typename=' + layer + '&' +
          'outputFormat=application/json&srsname=EPSG:3857&' +
          'bbox=' +
          extent.join(',') +
          ',EPSG:3857'
        );
      },
      strategy: bboxStrategy,
      ...sourceParams,
    }),
    style: new Style({
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 255, 1)',
        width: 2,
      })
    }),
    visible: false,
    ...params,
  })
}

export function kmlLayer(title, url, params = {}, sourceParams = {}) {
  return new VectorLayer({
    title: title,
    source: new VectorSource({
      format: new KML({
        crossOrigin: null,
      }),
      url: url,
      ...sourceParams
    }),
    visible: false,
    ...params,
  });
}